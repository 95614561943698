@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.products{
  width: 80rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items:center;
  position: absolute;
  left:8rem;
  top:18rem;
  
  column-gap: 10rem;
}

.cartItem {
  width: 50rem;
  height: 12rem;
}

.signup {
  height: 43.5rem;
}

.signup .content {
  height: 22rem;
  width: 20rem;
}

.login {
  height: 43.5rem;
}

.login .content {
  height: 18rem;
  width: 20rem;
}

.cart .footer{
  margin-top: 20rem;
}

.shop .footer{
  margin-top:130rem;
  
}


